import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'swiper/dist/css/swiper.css'
import {get, post } from "./utils/axios";
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)

// autd-start
import { Button } from 'ant-design-vue';
Vue.component(Button.name, Button)
import { DatePicker } from 'ant-design-vue';
Vue.component(DatePicker.name, DatePicker)
import { Input } from 'ant-design-vue';
Vue.component(Input.name, Input)
// const axios = require('axios');
// axios.defaults.withCredentials = true;
import { Dialog } from 'vant';
Vue.use(Dialog);
// autd-end

// vant-start
// vant-end

// Vue.prototype.$url ="https://xfb.tzgcs360.com"
Vue.prototype.$url ="https://中共台州市椒江区委区政府信访局.政务.cn"
Vue.prototype.$get =get 
Vue.prototype.$post =post

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
Vue.config.productionTip = false
window.onkeypress = (event) => {
  if(event.keyCode == 32) event.returnValue = false
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
