import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:"",
    refresh_token:"",
    exp:"",
    refresh_exp:""
  },
  mutations: {
    set_token(state,d){
      state.token=d.token
      state.refresh_token=d.refresh_token
      state.exp=d.exp
      state.refresh_exp=d.refresh_exp
    }
  },
  actions: {
  },
  modules: {
  }
})
