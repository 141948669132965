import Vue from 'vue'
import VueRouter from 'vue-router'

const index = () =>import('../views/home/index');
const team1 = () =>import('../views/content/team');
const reaction = () =>import('../views/content/reaction');
const plan = () =>import('../views/content/plan');
const people = () =>import('../views/content/people');
const care = () =>import('../views/content/care');
const comtent = () =>import('../views/content/comtent');
const login = () =>import('../views/login/login');
const register = () =>import('../views/login/register');
const editor = () =>import('../views/content/editor');
Vue.use(VueRouter)

const routes = [
  {
    path:'',
    redirect:'/index'
  },
  {
    path:'/login',
    component:index,
    meta: { hidden: true,title:"信访代办"}
  },
  // {
  //   path:'/login',
  //   component:login,
  //   meta: { hidden: true,title:"登录"}
  // },
  {
    path:'/register',
    component:register,
    meta: { hidden: true,title:"注册"}
  },
  {
    path:'/index',
    component:index,
    meta: { hidden: true,title:"信访代办"}
  },
  {
    path:'/team',
    component:team1,
    meta: { hidden: true,title:"代办团队"}
  },
  {
    path:'/reaction',
    component:reaction,
    meta: { hidden: true,title:"信访代办"}
  },
  {
    path:'/plan',
    component:plan,
    meta: { hidden: true,title:"代办进度"}
  },
  {
    path:'/people',
    component:people,
    meta: { hidden: true,title:"处理结果-个人代办事项"}
  },
  {
    path:'/care',
    component:care,
    meta: { hidden: true,title:"领导关怀"}
  },
  {
    path:'/comtent',
    component:comtent,
    meta: { hidden: true,title:"代办详情"}
  },
  {
    path:'/editor',
    component:editor,
    meta: { hidden: true,title:"代办详情修改"}
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
