import axios from "axios";
import QS from 'qs';
import router from '../router/index.js'
import store from '../store'

axios.defaults.withCredentials=true;
// axios.defaults.baseURL = "http://localhost:8080/api/admin";
// axios.defaults.baseURL = "http://192.168.0.130:8080/api/admin";
// axios.defaults.baseURL = "https://xfb.tzgcs360.com/api/admin";
axios.defaults.baseURL = "https://中共台州市椒江区委区政府信访局.政务.cn";
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
// axios.defaults.headers.common['token'] = store.state.token;

let isRefreshToken = false; //防止同一时间段多次刷新
var storage = window.localStorage;
var servicecode = "xfdbjfm";
/** 
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
 const toLogin = () =>{
    var d={token:"",refreshToken:"",exp:"",refresh_exp:""}
    //清除用户登录信息    
    store.commit("set_token",d) 
    storage.clear()    
    
    const sUserAgent = window.navigator.userAgent.toLowerCase();
    const bIsDtDreamApp = sUserAgent.indexOf("dtdreamweb") > -1; // 浙里办APP
    const bIsAlipayMini =
        sUserAgent.indexOf("miniprogram") > -1 &&
        sUserAgent.indexOf("alipay") > -1;
    
    
    if (bIsAlipayMini) {
        window.location.href =
            "https://puser.zjzwfw.gov.cn/sso/alipay.do?action=ssoLogin&servicecode=" + servicecode;
    } else if(bIsDtDreamApp){
        window.location.href =
            "https://puser.zjzwfw.gov.cn/sso/mobile.do?action=oauth&scope=1&servicecode=" + servicecode;
    }
}

/**  get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
                params: params
            })
            .then(res => {
                if(res.data==962){
                    toLogin()
                    return
                }
                // if(res.data==401){
                //     message.error("没有权限")
                //     return
                // }
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, QS.stringify(params))
            .then(res => {
                if(res.data==962){
                    toLogin()
                    return
                }
                // if(res.data==401){
                //     message.error("没有权限")
                //     return
                // }
                resolve(res.data);
            })
            .catch(err => {
                // notification.error({
                //     message: 'Forbidden',
                //     description: data.message
                // })          
                reject(err.data)
            })
    });
}
axios.interceptors.request.use(function (config) {
    console.log(config)
    if(config.url=="/front/login"||config.url=="/front/refresh_token"){
        return config
    }
    // 在发送请求之前做些什么，例如加入token
    var timestamp = Date.parse(new Date())/1000;
    var exp=storage.getItem("exp")
    var refresh_exp=storage.getItem("refresh_exp")

    let token = storage.getItem("token")
    if (token) {
        config.headers.common['Authorization'] = token;
    }

    if(timestamp>exp){
        console.log(refresh_exp)
        if(timestamp>refresh_exp){
            console.log("刷新token过期，跳转登录")
            toLogin()
        }else{
            refreshToken().then(res =>{
                // 重新设置token
                let token =localStorage.getItem('token') || '';
                config.headers.common['Authorization'] = token;
                return config;
            })
            .catch(error =>{ //请求失败的话重新登陆
                toLogin();
            })
        }
    }else{
        console.log(44444)
        return config;
    }
    
}, function (error) {
    
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 刷新token
function refreshToken() {
    return new Promise((resolve, reject) =>{
        console.log("refreshToken:1")
        config.headers.common['Authorization'] = localStorage.getItem('refresh_token') || '';
        // store.state.refresh_token;
        axios.post("/front/refresh_token",{}).then(res =>{
            if (res.data.status == 1) {
                // 重新存储token和失效时间
                // localStorage.setItem('token', res.data.data.authorization);
                // localStorage.setItem("expired_time", res.data.data.expired_time);
                // store.commit("set_token",res.data)
                storage.setItem("token", res.data.token);
                storage.setItem("refresh_token", res.data.refresh_token);
                storage.setItem("exp", res.data.exp);
                storage.setItem("refresh_exp", res.data.refresh_exp);
				// this.$store.dispatch("refresh_token",res.data.refresh_token)
				// this.$store.dispatch("exp",res.data.exp)
				// this.$store.dispatch("refresh_exp",res.data.refresh_exp)
                resolve();
            } else {
                // if (res.data.code == 2001) { //未认证
                    // toLogin();
                // }
                // router.push({path: "/login"})
                reject()
            }
        }).
        catch(error =>{
            reject()
        })
    })
}

const upvideoconfig = axios.create({
    headers: {
        'Content-Type': 'multipart/form-data',
    },
})

export function uploadfile(url, data) {
    return new Promise((resolve, reject) => {
        upvideoconfig
            .post(url, data)
            .then((resp) => {
                resolve(resp)
            })
            .catch((err) => {
                reject(err)
            })
    })
}