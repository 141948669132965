<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  components: {
  },
  methods: {

  },
  created () {

  },
}
</script>
<style>
@import "./assets/css/base.css";
</style>

